import React, { useEffect } from "react";
import {
  Form,
  ListGroup,
  Badge,
  OverlayTrigger,
  Popover,
  Button,
} from "react-bootstrap";
import { Link } from "gatsby";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  productsRequest,
  overlaysRequest,
  productsToggle,
  overlaysToggle,
  toggleSelectionMode,
  timeseriesUpdate,
  timeseriesFeatureUpdate,
  dateSet,
  setOverlayOpacity,
} from "actions";
import UNDPPALogo from "../assets/images/undppa-logo.png";
import "react-datepicker/dist/react-datepicker.css";
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next";

import {
  INDICATORS,
  OVERLAYS,
  DEFAULT_OVERLAY_OPACITY,
} from "../lib/constants";
import HeaderMainLogo from "./HeaderMainLogo";

const Sidebar = (props) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const { dispatch } = props;

  useEffect(() => {
    dispatch(productsRequest());
    dispatch(overlaysRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(timeseriesUpdate());
    dispatch(timeseriesFeatureUpdate());
  }, [dispatch, props.products, props.products_active, props.date]);

  useEffect(() => {
    registerLocale(`${language}`, language === "fr" ? fr : en);
  }, [language]);

  const onProductClick = (name) => {
    dispatch(productsToggle(name));
  };

  const onOverlayClick = (name) => {
    if (name === props.products_overlays_active[0]) {
      dispatch(overlaysToggle());
    } else {
      dispatch(overlaysToggle(name));
    }
  };

  var mmYYYY = (date) => {
    date = new Date(date.replace("Z", ""));
    return date.getMonth() + 1 + "/" + date.getFullYear();
  };

  const indicatorProducts = props.products.filter((p) => p.sidebar);
  const overlayProducts = props.products_overlays;
  const activeOverlays = props.products_overlays_active;

  const popover = (p) => (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{t(p.label ?? p.short_name)}</Popover.Title>
      <Popover.Content>
        <p
          dangerouslySetInnerHTML={{
            __html: t(p.description),
          }}
        />
      </Popover.Content>
    </Popover>
  );

  const renderProductListGroup = (title, translatedTitle, products) => {
    const active =
      title === INDICATORS
        ? props.products_active
        : props.products_overlays_active;

    const onClick = title === INDICATORS ? onProductClick : onOverlayClick;

    return (
      <>
        <h4 className="sidebar-header">{translatedTitle}</h4>

        <ListGroup variant="flush">
          {products.map((product) => (
            <OverlayTrigger
              trigger="click"
              placement="right"
              overlay={popover(product)}
              key={product.name}
              rootClose
            >
              {({ ref, ...triggerHandler }) => (
                <ListGroup.Item
                  ref={ref}
                  onClick={() => onClick(product.name)}
                  active={active.includes(product.name)}
                  className={product.category?.toLowerCase() ?? "other"}
                >
                  <div className="product-item-content">
                    <div className="content-left">
                      <h4>{t(product.label)}</h4>
                      {product.date_min && (
                        <small className="date-range">
                          {mmYYYY(product.date_min)} -{" "}
                          {mmYYYY(product.date_max)}
                        </small>
                      )}
                    </div>
                    {product.category && (
                      <Badge variant="primary" className={product.category}>
                        {t(product.category)}
                      </Badge>
                    )}
                    {!product.category &&
                      activeOverlays.includes(product.name) && (
                        <input
                          id={`${product.name}-opacity`}
                          type="range"
                          min="0"
                          max="100"
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            dispatch(
                              setOverlayOpacity(product.name, e.target.value)
                            );
                          }}
                          value={
                            props.overlays_opacities[product.name] ??
                            DEFAULT_OVERLAY_OPACITY
                          }
                        />
                      )}
                    <Button
                      variant="link"
                      className="ml-2 p-0 cursor-help product-help-button"
                      onClick={(e) => {
                        document.body.click();
                        e.stopPropagation();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        {...triggerHandler}
                      />
                    </Button>
                  </div>
                </ListGroup.Item>
              )}
            </OverlayTrigger>
          ))}
        </ListGroup>
      </>
    );
  };

  return (
    <aside className="sidebar z1">
      <HeaderMainLogo logo={<img src={UNDPPALogo} alt="UNDPPA Logo" />} />
      <div className="sidebar-inner-container">
        <div className="date-range-selection">
          <FontAwesomeIcon icon={faCalendarAlt} />
          <DatePicker
            selected={new Date(props.date)}
            onChange={(date) => dispatch(dateSet(date))}
            dateFormat="M/yyyy"
            showMonthYearPicker
            showYearDropdown
            minDate={new Date(props.date_min)}
            maxDate={new Date(props.date_max)}
            locale={`${language}`}
          />
          <Form>
            <Form.Group controlId="selectionMode">
              <Form.Control
                as="select"
                className="selection-mode-select"
                value={props.selectionMode}
                onChange={(e) => dispatch(toggleSelectionMode(e.target.value))}
              >
                <option key="Default Mode">{t("Default Mode")}</option>
                <option key="Multi Regions">{t("Multi Regions")}</option>
                <option key="Multi Indicators">{t("Multi Indicators")}</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </div>
        {renderProductListGroup(INDICATORS, t("Indicators"), indicatorProducts)}
        {renderProductListGroup(OVERLAYS, t("Overlays"), overlayProducts)}
      </div>
      <footer className="sidebar-footer px-3 py-2">
        <Link to="/help">
          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
          <Trans i18nKey="helpLink">Documentation and Help</Trans>
        </Link>
      </footer>
    </aside>
  );
};

export default Sidebar;
