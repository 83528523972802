import React, { useState, useEffect } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { layerSet, timeseriesFeatureUpdate } from "actions";

const LayerSelector = (props) => {
  const { t } = useTranslation();

  // Needed for react-bootstrap to hydrate correctly
  // https://github.com/vercel/next.js/issues/7322#issuecomment-987086391
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) return null;

  const onLayerClick = (name) => {
    props.dispatch(layerSet(name));
    props.dispatch(timeseriesFeatureUpdate());
  };

  return (
    <div className="layer-selector">
      <ButtonGroup aria-label="Layer Region Selector">
        {props.layers.map((layer) => (
          <Button
            variant="secondary"
            size="sm"
            key={layer.name}
            onClick={() => onLayerClick(layer.name)}
            active={props.layer_active === layer.name}
          >
            {t(layer.description)}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default LayerSelector;
