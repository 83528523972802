import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import { userDisclaimerAffirmationRequest } from "../actions";
import { HeaderSmall } from "./HeaderSmall";
import WaterSecurityLogo from "../assets/images/logo.svg";
import ExportControlContent from "./ExportControlContent";

const UserAffirmationModal = ({ dispatch, affirm }) => {
  const [affirmState, setAffirmState] = useState(affirm);
  const { t } = useTranslation();

  const handleAccept = () => {
    setAffirmState(true);
  };

  // dispatches affirmation status to redux state as a side effect of setting the component state
  useEffect(() => {
    dispatch(userDisclaimerAffirmationRequest(affirmState));
  }, [dispatch, affirmState]);

  return (
    <Modal show={!affirmState} onHide={handleAccept} backdrop="static" centered>
      <Modal.Header className="p-0 rounded-top overflow-hidden bg-dark-blue">
        <HeaderSmall
          hideRightLink={true}
          title={t("Welcome & Disclaimer")}
          logo={WaterSecurityLogo}
          appendClassName="w-100"
        />
      </Modal.Header>
      <Modal.Body className="pb-0">
        <h4>
          <Trans>Welcome</Trans>
        </h4>
        <p>
          <Trans>
            Click on an indicator or overlay to the left to visualize changes on
            the map. Upon selection of a region, view the available data on an
            interactive graph that can be expanded and collapsed at the bottom
            of the map. Toggle the map view between region or subregion. Click
            the calendar icon on the left panel to display data for the selected
            month on the map. View the Documentation and Help page to explore
            methodologies used, user guidance, and FAQs.
          </Trans>
        </p>
        <h4>
          <Trans>Disclaimer</Trans>
        </h4>
        <p>
          <Trans>
            Data sets have been averaged by month for the purpose of using a
            common scale. Grey regions on the map indicate that no data is
            available. For any missing values, a pot line will connect the last
            known data point to the next available data point on the graph.
            Missing data will not appear as a “zero” value and will not be
            factored into calculated indicators such as water scarcity.
          </Trans>
        </p>
        <p>
          <Trans>
            The designations employed and the presentation of material on this
            map do not imply the expression of any opinion whatsoever on the
            part of the Secretariat of the United Nations concerning the legal
            status of any country, territory, city or any area or of its
            authorities, or concerning the delimitation of its frontiers or
            boundaries.
          </Trans>
        </p>
        <ExportControlContent className="disclaimer-warning pb-3" />
      </Modal.Body>
      <Modal.Footer className="border-0 pt-0">
        <Button
          variant="primary"
          onClick={handleAccept}
          className="d-block w-50 mx-auto"
        >
          <Trans>{"Accept & Continue"}</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

UserAffirmationModal.propTypes = {
  dispatch: PropTypes.any,
  affirm: PropTypes.bool,
};

export default UserAffirmationModal;
