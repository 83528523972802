import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Loadable from "react-loadable";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog as faUserCogReg } from "@fortawesome/pro-regular-svg-icons";
import { isDomAvailable } from "../lib/util";
import Layout from "../components/Layout";
import Map from "../components/Map";
import Sidebar from "../components/Sidebar";
import LayerSelector from "../components/LayerSelector";
import NavLink from "../components/NavLink";
import { SITE_TITLE, SITE_DESCRIPTION } from "../lib/constants";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useTranslation } from "gatsby-plugin-react-i18next";
import UserAffirmationModal from "../components/UserAffirmationModal";

const LoadableGraphs = Loadable({
  loader: () => import("../components/Graphs"),
  loading() {
    return <div>Loading...</div>;
  },
});

const IndexPage = (props) => {
  // Needed for plotly to hydrate correctly
  // https://github.com/vercel/next.js/issues/7322#issuecomment-987086391
  const [hasMounted, setHasMounted] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!props.account_is_logged_in && isDomAvailable()) {
    navigate("/account/login");
    return <>Redirecting...</>;
  }

  if (!hasMounted) return null;

  const graphActive = !(
    !props.features_active[props.layer_active]?.length ||
    !props.timeseries_feature ||
    !props.timeseries_feature.map ||
    !props.graph_show
  );
  const { products_active: activeProducts } = props;

  return (
    <Layout
      pageName="home"
      siteTitle={t(SITE_TITLE)}
      siteDescription={t(SITE_DESCRIPTION)}
      secondaryNav={
        <NavLink
          linkPath="/account/"
          linkText="Account"
          linkIcon={<FontAwesomeIcon icon={faUserCogReg} size="lg" />}
        />
      }
      isMap={true}
    >
      <Helmet>
        <title>{t("siteTitle")}</title>
      </Helmet>
      <UserAffirmationModal
        affirm={props.user_disclaimer_affirmation}
        dispatch={props.dispatch}
      />
      <div
        className={`map-container ${
          graphActive && activeProducts.length ? "graph-expanded" : ""
        }`}
      >
        <Map {...props}></Map>
        <LayerSelector {...props} />
      </div>
      <Sidebar {...props} />
      {graphActive ? <LoadableGraphs {...props} /> : null}
    </Layout>
  );
};

export default connect((state) => state)(IndexPage);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "index", "products", "regions"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
