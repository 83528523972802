import React, { useEffect, useMemo, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { isDomAvailable } from "lib/util";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import { COMPOSITE, DEFAULT_OVERLAY_OPACITY } from "../lib/constants";

import { toggleFeature, graphShowToggle } from "actions";
import Legend from "./Legend";

const MAPBOX_ACCESS_TOKEN =
  process.env.MAPBOX_ACCESS_TOKEN ||
  "pk.eyJ1IjoiZWxlbWVudDg0IiwiYSI6ImNsYjE2a2tpbDBqN2Qzb3BmaHlpdW9zY2gifQ.SyZBF-HN-WPtze9KQ5oNIQ";
const MAPBOX_STYLE_ID =
  process.env.MAPBOX_STYLE_ID ||
  "mapbox://styles/element84/clb16r1bc000215lg3i3b1t3u"; //Default
const API = process.env.GATSBY_API_URL;
const DEFAULT_CENTER = [43.5, 33.5];
const DEFAULT_ZOOM = 5.5;

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

// prettier-ignore
const GREEN_RED = [
  "interpolate",
  ["linear"],
  ["to-number", ["feature-state", "indicator"]],
  -1, "#1a9641",
  -0.5, "#a6d96a",
  0, "#ffffbf",
  0.5, "#fdae61",
  1.0, "#d7191c",
];

// prettier-ignore
const RED_GREEN = [
  "interpolate",
  ["linear"],
  ["to-number", ["feature-state", "indicator"]],
  -1, "#d7191c",
  -0.5, "#fdae61",
  0, "#ffffbf",
  0.5, "#a6d96a",
  1.0, "#1a9641",
];

// prettier-ignore
const _GREY_SCALE = [
  "interpolate",
  ["linear"],
  ["to-number", ["feature-state", "indicator"]],
  -1, "#f0f0f0",
  0,  "#bdbdbd",
  1.00, "#636363"
];

// prettier-ignore
const GREY_BACKGROUND_SCALE = [
  "interpolate",
  ["linear"],
  ["to-number", ["feature-state", "indicator"]],
  -1, "#636363",
  0,  "#636363",
  1.00, "#636363"
];

// prettier-ignore
const YELLOW_RED = [
  "interpolate",
  ["linear"],
  ["to-number", ["feature-state", "indicator"]],
  -1, "#ffeda0",
  0,  "#feb24c",
  1.00, "#f03b20"
];

// prettier-ignore
const RED_BLUE = [
  "interpolate",
  ["linear"],
  ["to-number", ["feature-state", "indicator"]],
  -1, "#b2182b",
  -0.75, "#d6604d",
  -0.5, "#f4a582",
  -0.25, "#fddbc7",
  0, "#f7f7f7",
  0.25, "#d1e5f0",
  0.5, "#92c5de",
  0.75, "#4393c3",
  1, "#2166ac",
];

// prettier-ignore
const DIVERGING_OPACITY = [
  "interpolate",
  ["linear"],
  ["to-number", ["feature-state", "indicator"]],
  -1, 0.8,
  -0.4, 0.7,
  -0.2, 0.5,
  -0.1, 0.3,
  0, 0.1,
  0.1, 0.3,
  0.2, 0.5,
  0.4, 0.7,
  1.0, 0.8,
];

// prettier-ignore
const _SEQUENTIAL_OPACITY = [
  "interpolate",
  ["linear"],
  ["to-number", ["feature-state", "indicator"]],
  -1, 0.1,
  -0.5, 0.2,
  0, 0.4,
  0.5, 0.5,
  1.0, 0.6,
];

let visibleProduct = null;

const Map = (props) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [mapLoading, setMapLoading] = useState(true);
  const { dispatch } = props;
  const [colorScale, setColorScale] = useState([]);
  const { t, i18n } = useTranslation();

  // This intentionally overwrites the global visibleProduct.
  // Yuk, but needed for hovering, see "Only display map tooltip..."
  visibleProduct =
    !props.visible_tab ||
    props.products_active.length === 1 ||
    (Number.parseInt(props.visible_tab) && props.visible_tab !== COMPOSITE)
      ? props.products_active[0]
      : props.visible_tab;

  useEffect(
    () => {
      if (props.products_active.length && props.products) {
        let product = props.products.find(
          (product) => product["name"] === visibleProduct
        );

        if (!product) {
          // Composite
          setColorScale(RED_BLUE);
        } else if (product.name === "lst") {
          setColorScale(GREEN_RED);
        } else if (product.category === "Supply") {
          setColorScale(RED_BLUE);
        } else if (product.category === "Demand") {
          setColorScale(RED_GREEN);
        } else if (product.category === "Conflict") {
          setColorScale(YELLOW_RED);
        } else if (product.category === "Other") {
          setColorScale(RED_GREEN);
        }
      }
    },
    // visibleProduct isn't really an outer variable.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.products, props.products_active, props.visible_tab, visibleProduct]
  );

  useEffect(() => {
    let onFeatureClick = (f) => {
      dispatch(toggleFeature(f.id, f.properties));
      dispatch(graphShowToggle(true));
    };

    if (map != null || !mapRef.current) {
      update_layers(
        t,
        i18n.language,
        map,
        props.features_active,
        props.layers,
        props.layer_active,
        onFeatureClick
      );
    } else {
      let new_map = new mapboxgl.Map({
        container: mapRef.current,
        style: MAPBOX_STYLE_ID,
        center: DEFAULT_CENTER,
        zoom: DEFAULT_ZOOM,
        maxZoom: 8,
      });

      let nav = new mapboxgl.NavigationControl();
      new_map.addControl(nav, "bottom-right");

      new_map.on("load", () => {
        new_map.addSource("features", {
          type: "vector",
          tiles: [`${API}/governates_districts/{z}/{x}/{y}.pbf`],
        });

        update_layers(
          t,
          i18n.language,
          new_map,
          props.features_active,
          props.layers,
          props.layer_active,
          onFeatureClick
        );
        setMap(new_map);
        new_map.resize();
      });
      new_map.on("idle", () => {
        setMapLoading(false);
      });
      global.DEBUG_MAP_HANDLE = new_map;
    }
  }, [
    dispatch,
    mapRef,
    map,
    props.features_active,
    props.layers,
    props.layer_active,
    t,
    i18n.language,
  ]);

  // Watch for graph being expanded/closed and resize map to fit container
  useEffect(() => {
    if (map) {
      map.resize();
    }
  }, [map, props.graph_show, props.graph_expand]);

  const timeseries_products = useMemo(() => {
    return props.timeseries?.length
      ? props.timeseries.map((p) =>
          p["product_id"].includes(COMPOSITE) ? COMPOSITE : p["product_id"]
        )
      : [];
  }, [props.timeseries]);

  useEffect(() => {
    if (!map || mapLoading) {
      return;
    }

    props.layers.forEach((layer) => {
      var layer_features = map.querySourceFeatures("features", {
        sourceLayer: layer.name,
      });

      const active_layer_features = props.features_active[layer.name] ?? [];

      layer_features.forEach((feature) => {
        map.setFeatureState(
          {
            source: "features",
            sourceLayer: layer.name,
            id: feature.id,
          },
          { selected: active_layer_features.includes(feature.id) }
        );
        map.setFeatureState(
          {
            source: "features",
            sourceLayer: layer.name + "-labels",
            id: feature.id,
          },
          { selected: active_layer_features.includes(feature.id) }
        );
      });
    });
  }, [map, mapLoading, props.layers, props.features_active]);

  useEffect(
    () =>
      map &&
      !mapLoading &&
      props.timeseries_feature?.length &&
      props.products_active.length &&
      props.features_active &&
      timeseries_products.includes(visibleProduct)
        ? risk_style(
            map,
            props.products,
            visibleProduct,
            props.date,
            props.layer_active,
            props.features_active,
            props.timeseries,
            props.timeseries_feature,
            colorScale
          )
        : risk_style_reset(map, props.layer_active, props.timeseries_loading),
    // visibleProduct isn't really an outer scope variable.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      map,
      mapLoading,
      timeseries_products,
      visibleProduct,
      props.date,
      props.features_active,
      props.products,
      props.products_active,
      props.timeseries,
      props.timeseries_feature,
      props.layer_active,
      props.timeseries_loading,
    ]
  );

  useEffect(
    () =>
      update_overlays(
        map,
        props.products,
        props.products_active,
        props.products_overlays,
        props.products_overlays_active,
        props.overlays_opacities,
        props.date
      ),
    [
      map,
      props.products,
      props.products_active,
      props.products_overlays,
      props.products_overlays_active,
      props.overlays_opacities,
      props.date,
    ]
  );

  if (!isDomAvailable) {
    return (
      <div>
        <Trans>Loading...</Trans>
      </div>
    );
  }

  return (
    <div className="map" ref={mapRef}>
      {props.children}
      <Legend {...props} colors={colorScale} />
    </div>
  );
};

global.feature_id_hovering = null;
global.mousemove_time = false;
global.popup = null;
global.mapOnMousemove = null;
// Create a popup, but don't add it to the map yet.
var popup = new mapboxgl.Popup({
  closeButton: false,
  anchor: "bottom",
  offset: 5,
});

function add_layer(
  t,
  language,
  map,
  features_active,
  name,
  style,
  onFeatureClick
) {
  let label_field;

  // prettier-ignore
  let default_style = {
    id: name,
    type: "line",
    source: "features",
    "source-layer": name,
    paint: {
      "line-color": [
        "case",
          ["boolean", ["feature-state", "selected"], false], "#a5a",
        style["line-color"],
      ],
      "line-width": [
        "case",
          ["boolean", ["feature-state", "hover"], false], 4,
          ["boolean", ["feature-state", "selected"], false], 3,
        1.5,
      ],
      "line-offset": [
        "case",
          ["boolean", ["feature-state", "hover"], false], 2,
          ["boolean", ["feature-state", "selected"], false], 2,
        0,
      ]
    },
  };

  map.addLayer(default_style, "tunnel-simple");

  let fill_style = {
    id: name + "-fill",
    type: "fill",
    source: "features",
    "source-layer": name,
    layout: {},
    paint: {
      "fill-color": "#444",
      "fill-opacity": 0.2,
    },
  };

  map.addLayer(fill_style, name);

  let text_allow_overlap = false;
  if (name === "governates") {
    label_field = "name_1";
  } else if (name === "districts") {
    label_field = "name_2";
  }

  // French does not have translated values avialable as part of properties schema yet
  if (language != "en" && language != "fr") {
    label_field += "_" + language;
  }

  // prettier-ignore
  map.addLayer(
    {
      id: name + "-labels",
      type: "symbol",
      source: "features",
      "source-layer": name + "-labels",
      layout: {
        // get the title name from the source's "title" property
        "text-field": ["get", label_field],
        "text-font": ["DIN Pro Bold", "Arial Unicode MS Bold"],
        // "text-ignore-placement": true,
        "text-allow-overlap": text_allow_overlap,
        // "text-offset": [0, 0],
        // "text-anchor": "top",
        "text-size": 14
      },
      paint: {
        "text-color": [
          "case",
            ["boolean", ["feature-state", "hover"], false], "#000",
            ["boolean", ["feature-state", "selected"], false], "#000",
          "#fff",
        ],
        "text-halo-color": [
          "case",
            ["boolean", ["feature-state", "hover"], false], "#fff",
            ["boolean", ["feature-state", "selected"], false], "#fff",
          "#000",
        ],
        "text-halo-width": [
          "case",
            ["boolean", ["feature-state", "hover"], false], 2,
            ["boolean", ["feature-state", "selected"], false], 3,
          1,
        ]
      },
    },
    "poi-label"
  );

  if (global.mapOnMousemove) {
    map.off("mousemove", global.mapOnMousemove);
  }
  global.mapOnMousemove = function (e) {
    global.mousemove_time = Date.now();

    try {
      var features = map.queryRenderedFeatures(e.point, {
        layers: map.getLayer("conflict-layer")
          ? [name + "-fill", "conflict-layer"]
          : [name + "-fill"],
      });
    } catch (error) {
      return;
    }

    if (features.length > 0) {
      const fills = features.filter((f) => f.layer.id === name + "-fill");
      const conflicts = features.filter((f) => f.layer.id === "conflict-layer");

      const feature = fills[0];
      const activeLayerFeatures = features_active[name] ?? [];

      map.getCanvas().style.cursor = "pointer";
      [...activeLayerFeatures, global.feature_id_hovering].forEach(
        (other_id) => {
          map.setFeatureState(
            { source: "features", sourceLayer: name, id: other_id },
            { hover: false }
          );
          map.setFeatureState(
            {
              source: "features",
              sourceLayer: name + "-labels",
              id: other_id,
            },
            { hover: false }
          );
        }
      );

      map.setFeatureState(
        { source: "features", sourceLayer: name, id: feature.id },
        { hover: true }
      );
      map.setFeatureState(
        {
          source: "features",
          sourceLayer: name + "-labels",
          id: feature.id,
        },
        { hover: true }
      );
      global.feature_id_hovering = feature.id;

      // Handle map hover of individual regions and add label + % increase/descrease
      // Translated labels only available on schema for "ar", use i18next resources for fr
      const translatableFromSchemaSuffix =
        language !== "en" && language !== "fr" ? "_" + language : "";

      let content = t(
        feature.properties["name_1" + translatableFromSchemaSuffix]
      );

      if (feature.properties["name_2" + translatableFromSchemaSuffix]) {
        let name_2 = t(
          feature.properties["name_2" + translatableFromSchemaSuffix]
        );
        content = "<strong>" + name_2 + "</strong>,&nbsp;" + content;
      }
      if (feature.properties["name_0" + translatableFromSchemaSuffix]) {
        let name_0 = t(
          feature.properties["name_0" + translatableFromSchemaSuffix]
        );
        content = content + ", " + "<i>" + name_0 + "</i>&nbsp;";
      }
      if (conflicts.length) {
        // Do conflict popup content on hover of conflict clusters
        const props = conflicts[0].properties;
        const start_date = new Date(props.time_start * 1000).toLocaleDateString(
          "en-US"
        );
        const end_date = new Date(props.time_end * 1000).toLocaleDateString(
          "en-US"
        );

        const date_str =
          start_date !== end_date ? start_date + " - " + end_date : start_date;

        const low_high =
          props.low !== props.high ? props.low + " - " + props.high : props.low;

        content = "<h6>" + content + "&nbsp;<i>";

        if (props.low != props.high) {
          content += "~";
        }

        content += props.best + " " + t("deaths") + "</i></h6>";

        if (props.cluster) {
          content += "<ul>";
          content +=
            "<li><b>" + props.point_count + "</b> " + t("conflicts") + ".</li>";
          content += "<li>" + date_str + "</li>";
          content += "<li>" + low_high + " " + t("deaths") + "</li>";

          if (props.conflict_names) {
            let names = props.conflict_names.split("|");
            // Make unique
            names = names.filter((v, i, a) => a.indexOf(v) === i);
            names = names.filter((v) => v != "");
            names.map((name) => {
              content += "<li>" + name + "</li>";
            });
          }
          content += "</ul>";
        } else {
          content += "<ul>";
          content += "<li>" + date_str + "</li>";
          content += "<li>" + props.conflict_name + "</li>";
          if (props.where_description) {
            content += "<li>" + props.where_description + "</li>";
          }
          content += "<li>" + low_high + ` ${t("deaths")}</li>`;
          content +=
            "<li>" + t("Source") + " - " + props.source_article + "</li>";
          content += "</ul>";
        }
      } else {
        // Do region info popup content
        content = "<h6>" + content + "</h6>";

        if (feature.state && feature.state.indicator) {
          const percentDiff = (feature.state.indicator * 100).toFixed(0);
          // Add CSS class for negative vs. positive to colorize green/red
          let indicatorClass = "positive";
          if (percentDiff < 0) {
            indicatorClass = "negative";
          }
          content += `<span class='indicator ${indicatorClass}'>
              <i class="arrow ${
                indicatorClass === "positive" ? "up" : "down"
              }"></i>
              ${percentDiff} %
            </span>`;

          if (feature.state.ymt !== undefined) {
            content +=
              `<br><span>${t("Month Average")}: ` +
              feature.state.ymt.toFixed(2) +
              "</span>" +
              `<br><span>${t("Historical Average")}: ` +
              feature.state.ht.toFixed(2) +
              "</span>" +
              `<br><span>${t("Variance")}: ` +
              feature.state.value.toFixed(2) +
              "</span>";
          }
        }
      }

      let ll = e.lngLat;
      // if (feature.properties.polylabel) {
      //   const polylabel = feature.properties.polylabel.split(",");
      //   ll = {
      //     lng: polylabel[0],
      //     lat: polylabel[1],
      //   };
      // }

      /* Only display map tooltip if there's an active product selected */
      if (visibleProduct) {
        popup.setLngLat(ll).setHTML(content).addTo(map);
      }
    } else {
      let timeout = 500;
      setTimeout(() => {
        var now = Date.now();
        if (global.mousemove_time + timeout > now) {
          return;
        }

        map.getCanvas().style.cursor = "";
        if (global.feature_id) {
          map.setFeatureState(
            { source: "features", sourceLayer: name, id: global.feature_id },
            { hover: false }
          );
          global.feature_id = null;
        }
        popup.remove();
      }, timeout);
    }
  };

  map.on("mousemove", global.mapOnMousemove);

  // Unregister any click listeners
  // This used to be done more idiomatically with map.off(...),
  // storing function references as a global, but those stopped
  // matching correctly, so we're just hacking into the map now.
  map._listeners.click = [];

  // Open graphs when clicking region of map
  const mapOnClick = function (e) {
    if (e.features.length > 0) {
      const fills = e.features.filter((f) => f.layer.id === name + "-fill");
      const feature = fills[0];

      onFeatureClick(feature);
    }
  };
  map.on("click", name + "-fill", mapOnClick);
}

function remove_layer(map, name) {
  if (map.getLayer(name)) {
    map.removeLayer(name);
  }
  if (map.getLayer(name + "-fill")) {
    map.removeLayer(name + "-fill");
  }
  if (map.getLayer(name + "-labels")) {
    map.removeLayer(name + "-labels");
  }
}

function update_layers(
  t,
  language,
  map,
  features_active,
  layers,
  layer_active,
  onFeatureClick
) {
  layers.map((layer) => {
    if (layer_active === layer.name) {
      if (!map.getLayer(layer.name)) {
        add_layer(
          t,
          language,
          map,
          features_active,
          layer.name,
          layer,
          onFeatureClick
        );
      }
    } else {
      remove_layer(map, layer.name);
    }
    return null;
  });
}

function risk_style_reset(map, layer_active) {
  if (!map || !layer_active) {
    return;
  }
  let layer_fill = layer_active + "-fill";
  map.setPaintProperty(layer_fill, "fill-color", "#444");
  map.setPaintProperty(layer_fill, "fill-opacity", 0.2);
}

function risk_style(
  map,
  products,
  visibleProduct,
  date,
  layer_active,
  features_active,
  timeseries,
  timeseries_feature,
  colorScale
) {
  let product = products
    .filter((product) => product["name"] === visibleProduct)
    .pop();

  let feature_risk = {};
  timeseries.forEach((row) => {
    if (
      row["product_id"] === visibleProduct ||
      // Multi-indicator composites
      (visibleProduct === COMPOSITE &&
        row["product_id"].includes(visibleProduct))
    ) {
      feature_risk[row["feature_id"]] = {
        ratio: row["ratio"],
        value: row["value"],
        ymt: row["ymt"],
        ht: row["ht"],
      };
    }
  });

  // Multi-region: set composite risk according to selected date
  // Multi-indicator composites handled above
  const date_as_date = typeof date === "number" ? new Date(date) : date;
  const mm_dd_yyyy = date_as_date.toISOString().split("T")[0];
  const composite_risk = timeseries_feature
    .filter((row) => row.type === "multi-feature")
    .find((row) => row.date.startsWith(mm_dd_yyyy));
  feature_risk[COMPOSITE] = { ratio: composite_risk?.ratio };

  let layer_fill = layer_active + "-fill";

  var related_features = map.querySourceFeatures("features", {
    sourceLayer: layer_active,
  });

  const layerFeatures = features_active[layer_active] ?? [];

  related_features.forEach((feature) => {
    let risk = feature_risk[feature.id] ?? feature_risk[visibleProduct];
    if (layerFeatures.length > 1 && layerFeatures.includes(feature.id)) {
      risk = feature_risk[COMPOSITE];
    }
    if (risk) {
      map.setFeatureState(
        { source: "features", sourceLayer: layer_active, id: feature.id },
        {
          indicator: risk["ratio"],
          value: risk["value"],
          ymt: risk["ymt"],
          ht: risk["ht"],
        }
      );
    }
  });

  let OPACITY_SCALE = 0.2;

  if (product === undefined) {
    // composite
    OPACITY_SCALE = DIVERGING_OPACITY;
  } else if (product["category"] === "Supply") {
    OPACITY_SCALE = DIVERGING_OPACITY;
  } else if (product["category"] === "Demand") {
    OPACITY_SCALE = DIVERGING_OPACITY;
  } else if (product["category"] === "Other") {
    OPACITY_SCALE = DIVERGING_OPACITY;
  } else if (product["category"] === "Conflict") {
    colorScale = GREY_BACKGROUND_SCALE;
  }

  map.setPaintProperty(
    layer_fill,
    "fill-color",
    ["case", ["!=", ["feature-state", "indicator"], null], colorScale, "#fff"],
    false
  );

  // prettier-ignore
  map.setPaintProperty(
    layer_fill,
    "fill-opacity",
    ["case", ["!=", ["feature-state", "indicator"], null], OPACITY_SCALE, 0.1],
    false
  );
}

function update_overlays(
  map,
  products,
  products_active,
  overlays,
  overlays_active,
  overlays_opacities,
  date
) {
  if (
    !map ||
    !products ||
    !products.length ||
    !products_active ||
    !overlays ||
    !overlays_active
  ) {
    return;
  }

  products.forEach((product) => {
    if (product.name == "ucdp") {
      ucdp_remove(map);
      if (products_active.indexOf("ucdp") > -1 && date) {
        ucdp_add(map, date);
      }
    }
  });

  overlays.forEach((overlay) => {
    if (overlays_active.includes(overlay.name)) {
      if (!map.getLayer(overlay.name)) {
        map.addSource(overlay.name, {
          type: overlay.type,
          url: overlay.url,
        });
        map.addLayer({
          id: overlay.name,
          type: overlay.type,
          source: overlay.name,
        });
      }
      const opacity = Number.parseInt(
        overlays_opacities[overlay.name] ?? DEFAULT_OVERLAY_OPACITY
      );
      map.setPaintProperty(overlay.name, "raster-opacity", opacity / 100);
    } else {
      if (map.getLayer(overlay.name)) {
        remove_layer(map, overlay.name);
      }
      if (map.getSource(overlay.name)) {
        map.removeSource(overlay.name);
      }
    }
  });
}

function ucdp_add(map, date) {
  const date_ = new Date(date);
  let time_start = date_.getTime() / 1000;
  let time_end =
    new Date(date_.setMonth(date_.getMonth() + 1)).getTime() / 1000;

  map.addSource("conflict", {
    type: "geojson",
    data:
      API +
      "/layer/iraq-conflict.json?" +
      "properties__time_start__lte=" +
      time_end +
      "&properties__time_start__gte=" +
      time_start,
    cluster: true,
    clusterMaxZoom: 18,
    clusterRadius: 35,
    clusterProperties: {
      best: ["+", ["get", "best"]],
      low: ["+", ["get", "low"]],
      high: ["+", ["get", "high"]],
      time_start: ["min", ["get", "time_start"]],
      time_end: ["max", ["get", "time_end"]],
      // conflict_names: [
      //   "concat",
      //   ["concat", ["accumulated"], "|", ["get", "conflict_name"]],
      // ],
    },
  });

  // prettier-ignore
  map.addLayer({
    id: "conflict-layer",
    source: "conflict",
    type: "circle",
    paint: {
      "circle-stroke-width": 1,
      "circle-stroke-color": "#fff",
      "circle-color": [
        "interpolate",
        ["linear"],
        ["get", "best"],
        1, "#ffeda0",
        10,  "#feb24c",
        500, "#f03b20",
        1000, "#b2182b"
      ],
      "circle-radius": [
        "interpolate",
        ["linear"],
        ["get", "best"],
        1, 11,
        50,  15,
        300, 20,
        1500, 30
      ]
    },
    //filter: ['has', 'point_count'],
  }, "poi-label");

  map.addLayer({
    id: "conflict-label",
    type: "symbol",
    source: "conflict",
    layout: {
      "text-field": ["number-format", ["get", "best"], {}],
      "text-font": ["Montserrat Bold", "Arial Unicode MS Bold"],
      "text-size": 11,
    },
    paint: {
      "text-color": "#fff",
      "text-halo-color": "#200",
      "text-halo-width": 0.8,
    },
  });
}

function ucdp_remove(map) {
  if (map.getLayer("conflict-label")) {
    map.removeLayer("conflict-label");
  }
  if (map.getLayer("conflict-layer")) {
    map.removeLayer("conflict-layer");
  }
  if (map.getSource("conflict")) {
    map.removeSource("conflict");
  }
}

export default Map;
