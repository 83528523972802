import React from "react";
import PropTypes from "prop-types";
import { WARNING_TEXT, WARNING_TITLE } from "../lib/constants";
import { Trans } from "gatsby-plugin-react-i18next";

/**
 * shows explicit warning/disclaimer text
 * @param {HTMLElement|ReactElement} [as="div"] - wrapper component
 * @param {string} [className=""] - className for wrapper component
 * @param {bool} [plainText=false] - render output as plain text string instead of HTMLElement
 * @returns {HTMLElement|string}
 */
const ExportControlContent = ({
  as = "div",
  className = "",
  plainText = false,
}) => {
  const Wrapper = as;
  const warningTitle = WARNING_TITLE;
  const warningText = WARNING_TEXT;

  if (plainText) {
    return `${warningTitle}${warningText}`;
  }

  return (
    <Wrapper className={className}>
      <small>
        <strong className="text-black">
          <Trans i18nKey="warningTitle">{warningTitle}</Trans>
        </strong>
        <span className="text-muted">
          <Trans i18nKey="warningText">{warningText}</Trans>
        </span>
      </small>
    </Wrapper>
  );
};

ExportControlContent.propTypes = {
  as: PropTypes.any,
  className: PropTypes.string,
  plainText: PropTypes.bool,
};

export default ExportControlContent;
