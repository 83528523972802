import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";

const Legend = (props) => {
  const slicedValues = props.colors.slice(3);
  let colors = slicedValues.filter((element, index) => {
    return index % 2 !== 0;
  });

  let values = slicedValues.filter((element, index) => {
    return index % 2 === 0;
  });

  return (
    <aside className="legend">
      {props.products_active.length > 0 && colors && colors.length > 0 && (
        <>
          <h5>
            <Trans>
              Percent Variance
              <br />
              <small>from historical average</small>
            </Trans>
          </h5>

          <ul>
            {colors.map((color, i) => {
              return (
                <li key={i}>
                  <span
                    className="legend-color"
                    style={{ backgroundColor: color }}
                  ></span>
                  <label>{values[i] * 100} %</label>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </aside>
  );
};

export default Legend;
